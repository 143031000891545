import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/header";
import Footer from "../Components/footer";
import HeroIMG from "../Assets/nfc-card-phone.png";
import HeroIMG2 from "../Assets/alldashboard.png";
import demoVideoIMGBG from "../Assets/demo-video-placeholder.png";
import NFCCardIMG from "../Assets/nfc-card.png";
import QrPhoneIMG from "../Assets/qrphone.png";
import CardPhoneQRIMG from "../Assets/card-phone-qr.png";
import QRContactCardIMG from "../Assets/qr-contact-card.png";
import AllDashboard from "../Assets/alldashboard.png";

import OnlinePlatformIMG from "../Assets/onlineplatform.png";
import productBlackCardIMG from "../Assets/blackcard.gif";
import productWhiteCardIMG from "../Assets/whitecard.gif";
import productGoldCardIMG from "../Assets/goldcard.gif";
import productBambooCardIMG from "../Assets/bamboocard.gif";
import productBlackMetalCardIMG from "../Assets/blackmetal.gif";
import productSilverMetalCardIMG from "../Assets/silvermetal.gif";
import productTagIMG from "../Assets/nfctag.gif";
import productWristBandIMG from "../Assets/wrist-band.gif";

import { demo_profile_url, name, youtube_demo_video, web_register_url, web_login_url } from "../Constant";
import { NavLink } from "react-router-dom";
import { randomNum, toMoney } from "../Components/core";
import { name_short } from "../Constant";
import CardApi from "../Components/cardapi";

import $ from "jquery";

const Testimonies = [
{
name: "Jerry Mayowa",
image: "https://qliqc.com/assets/media/jerry.jpg",
testimony: "As a business owner, I'm always looking for ways to stand out from the competition. My Qliq NFC business cards have definitely helped me do that. People are always impressed when I hand them a card and they're able to instantly access my website or social media profiles.",
},
{
name: "Josh Patrick",
image: "https://qliqc.com/assets/media/joshua.jpg",
testimony: "I was hesitant to switch to Qliq NFC business cards at first, but I'm so glad I did. They're much more eco-friendly than traditional paper cards, and they make it easy for me to stay connected with clients and colleagues.",
},
{
name: "Victoria Damilola",
image: "https://qliqc.com/assets/media/vickynicky.jpg",
testimony: "I've gotten so many compliments on my business cards. They're a great conversation starter and they really help me make a lasting impression on potential clients.",
},
{
name: "Amaka",
image: "https://qliqc.com/assets/media/amaka.jpg",
testimony: "I love my new NFC business card! It's so convenient to be able to share my contact information with just a single tap on my client's phone. Plus, the design is sleek and professional. Highly recommend!",
},
{
name: "Unoma Faith",
image: "https://qliqc.com/assets/media/unoma.jpg",
testimony: "All i have to say is amazing!!!!",
}
];




declare global {
  var interval: Timer;
}

type Timer = ReturnType<typeof setInterval>;


class Index extends React.Component <any> {

constructor(props){
    super(props);
    this.state = {
    testi_: 3,
    message : '',
    };
}

componentDidMount(): void {
let that = this;

$(function(){
let $classesTesti = {1: 2, 2: 3, 3: 4, 4: 5, 5: 1};
let $classesTestiX = {1: 1, 2: 2, 3: 3, 4: 4, 5: 5};

globalThis.interval = setInterval(function(){
let oldTesti = that?.state['testi_'];
that?.setState({['testi_']: $classesTesti[that?.state['testi_']]});
$('label.item').removeClass('active');
$('label.item[for='+'t_'+$classesTestiX[oldTesti]+']').addClass('active');
}, 6000);
});
}

componentWillUnmount(): void {
  clearInterval(globalThis.interval);
}

render(): ReactNode {
    return(
<>
<Header classX="contain">
<div className="hero-lt">
<div className="hero-grid">
<div>
<div className="sub-ob">
<div>
<div className="title animated animatedFadeInUp fadeInUp">
The NFC enabled digital Business card for any business.
</div>
<div className="sub-title animated animatedFadeInUp fadeInUp">
Create and share your digital business profile easily. 
Design your profile to match your brand, share it with customers around the globe, and start building connections with our NFC products.
</div>

<div className="bt-wp-pd animated animatedFadeInUp fadeInUp">
<div>
<NavLink to="/shop">
<button className="shop-btn">
 <i className="fa fa-shopping-cart"></i> Buy QLIQ business card   
 </button>
</NavLink>
</div>
<div>
<a href={web_register_url}>
    <button className="signup-btn">
    Join for Free
    </button>
</a>
</div>
</div>

</div>
</div>
</div>
<div>
<img className="p1 animated animatedFadeInUp fadeInUp" src={HeroIMG} alt={name} />
<img className="p2 animated animatedFadeInUp fadeInUp" src={HeroIMG2} alt={name} />
</div>
</div>
</div>
</Header>

<div className="grey-d-bg" id="what-is-qliqc">
<div className="container">
<div className="gt-row col-2">
<div className="what-demo-video-text">
<h1>Why Qliq Card?</h1>
<p>Qliq Card is a social platform that allows you to create and share contact information, social media profiles by using our website and other Qliq products.</p>

<p>Customize your digital profile to match your brand. Choose from 21 beautiful themes and 5 unique colors! Edit your banner, logo, title, description, and more! - All in a few clicks.</p>

<p>Help your customers save your contact information quickly and easily. Save your customers the trouble of having to save your contact manually, They just tap the save icon on your business profile and it's done.</p>

<p className="p-btn-bt">
<br/>

<a href={demo_profile_url}>
<button className="pg-btn">
 Demo business Profile <i className="fa fa-arrow-right hidden"></i>
 </button>
</a>
</p>
</div>

<div className="what-demo-video-video">
<a className="popup-demo-video demo-video" href={youtube_demo_video}>
<img src={demoVideoIMGBG} alt="Demo Video" />
<div className="play-video-wp">
<div className="play-video-btn"><i className="fa fa-play"></i></div>
</div>
</a>
</div>

</div>
</div>
</div>

<div className="white-d-bg" id="how-it-works">
<div className="container">

<div className="grid-4-with-ic">

<h1>How it works</h1>

<div className="gt-row col-4">
<div>

<div className="icon-ilust">
<i className="fa fa-plus-circle"></i>
</div>

<h2>Create a digital profile</h2>
<p>
Create a digital profile via our website or Mobile App and edit to your liking.
</p>
</div>

<div>
<div className="icon-ilust">
<i className="fa fa-check-circle"></i>
</div>
<h2>Choose Plan</h2>
<p>
Decide on the plan suitable for your business.
</p>
</div>

<div>
<div className="icon-ilust">
<i className="fa fa-shopping-cart"></i>
</div>
<h2>Purchase NFC card</h2>
<p>
Choose from our various NFC products that is suitable for your business.
</p>
</div>

<div>
<div className="icon-ilust">
<i className="fa fa-network-wired"></i>
</div>
<h2>Start networking</h2>
<p>
Share contact details with a tap of the card on your phone and amaze people with your unique Qliq Card
</p>
</div>

</div>
</div>
</div>
</div>


<div className="white-d-bg" id="short-plans">
<div className="container">
<div className="grid-3-for-shop">

<h1>{name} NFC products</h1>

<CardApi apiUrl="https://qliqc.com/api/getcardfeatured" />

{/* <div className="gt-row col-4"> */}

{/* <div>
<p className="center">
<img src={Card1[this?.state['Card1']]['image']} alt="NFC Card"/>
</p>
<h2>{name_short} {Card1[this?.state['Card1']]['name']}</h2>
<h4>{toMoney(Card1[this?.state['Card1']]['price'])}</h4>
</div>

<div>
<p className="center">
<img src={Card2[this?.state['Card2']]['image']} alt="NFC Bamboo Card"/>
</p>
<h2>{name_short} {Card2[this?.state['Card2']]['name']}</h2>
<h4>{toMoney(Card2[this?.state['Card2']]['price'])}</h4>
</div> */}


{/* <div>
<p className="center">
<img src={productWristBandIMG} alt="NFC Wrist Band"/>
</p>

<h2>{name_short} NFC Wrist-Band</h2>
<h4>{toMoney(13000.00)} <span className="slash">15000</span></h4>
</div>

<div>
<p className="center">
<img src={productTagIMG} alt="NFC Tag"/>
</p>

<h2>{name_short} NFC Tag</h2>
<h4>{toMoney(10000.00)}</h4>
</div>

<div>
<p className="center">
<img src={productWristBandIMG} alt="NFC Wrist Band"/>
</p>

<h2>{name_short} NFC Wrist-Band</h2>
<h4>{toMoney(13000.00)} <span className="slash">15000</span></h4>
</div>

<div>
<p className="center">
<img src={productTagIMG} alt="NFC Tag"/>
</p>

<h2>{name_short} NFC Tag</h2>
<h4>{toMoney(10000.00)}</h4>
</div>

</div> */}



<div className="center"><br/>
<NavLink to="/shop">
<button className="btn">
<i className="fa fa-shopping-cart"></i> See all NFC Products
 </button>
</NavLink>
</div>

</div>
</div>
</div>


<div className="grey-d-bg">
<div className="container">
<div className="grid-2-for-exp">

<div className="gt-row col-2">
<div className="exp-img-text">
<h2>Create and share business details with a single tap</h2>
<p>Create and share contact information, social media links by using our NFC products.

<br/><br/>What you can add to your digital business profile:

<ul>
<li>Your name, job title and company</li>

<li>Email, phone number and address</li>

<li>Your photo , cover page and gallery</li>

<li>Your social media links and websites</li>

<li>Sevices and testimonials</li>
</ul>

<br/>
<div>
<NavLink to="/features">
<button className="pg-btn">
Learn more <i className="fa fa-arrow-right hidden"></i>
 </button>
</NavLink>
</div>
</p>
</div>
<div className="exp-img-image">
<img src={CardPhoneQRIMG} alt="Image" />
</div>
</div>
</div>
</div>
</div>

<div className="white-d-bg">
<div className="container">
<div className="grid-2-for-exp">

<div className="gt-row col-2">

<div className="exp-img-image exp-1">
<img src={QRContactCardIMG} alt="Image" />
</div>

<div className="exp-img-text">
<h2>Reach a larger audience</h2>
<p>The direct contact exchange function is a game-changer for networking and relationship-building. It streamlines the process and ensures that you stay connected with the right people.

<br/><br/>

This makes Qliq Card the best for you.

<br/><br/>

<div>
<NavLink to="/features">
<button className="pg-btn">
See all features <i className="fa fa-arrow-right hidden"></i>
 </button>
</NavLink>
</div>
</p>
</div>

<div className="exp-img-image exp-2">
<img src={QRContactCardIMG} alt="Image" />
</div>

</div>
</div>
</div>
</div>

<div className="grey-d-bg">
<div className="container">
<div className="grid-2-for-exp">

<div className="gt-row col-2">
<div className="exp-img-text">
<h2>Control everything from one place</h2>
<p>Real-time updates. Manage your digital business cards and contact details online.
<br/><br/>
Easily manage your digital business cards by controlling everything from one dashboard. View and edit all essential business info, keep track of your appointments, and get a view of who is looking at your business profile - all under one dashboard.

<br/><br/>

<div>
<NavLink to="/features">
<button className="pg-btn">
 Learn more <i className="fa fa-arrow-right hidden"></i>
 </button>
</NavLink>
</div>
</p>
</div>
<div className="exp-img-image">
<img src={AllDashboard} alt="Image" />
</div>
</div>
</div>
</div>
</div>


<div className="white-d-bg" id="info-9">
<div className="container">

<div className="grid-3-with-draf-img">

<h1>The Most Effective Way To Expand Your Reach</h1>
<h4> We give you all the tools to share your business card as easy as possible</h4>
<div className="gt-row col-3">

<NavLink to="/features">
<div>
<h4>Share via</h4>
<h1>NFC Business card</h1>
<h5>Contactless & paperless</h5>
<p>
<img src={NFCCardIMG} alt="Image" />
</p>
<p className="learn-more">Learn more &raquo;</p>
</div>
</NavLink>

<NavLink to="/features">
<div>
<h4>Share via</h4>
<h1>QR Code</h1>
<h5>In your IPhone or Apple Watch</h5>
<p>
<img src={QrPhoneIMG} alt="Image" />
</p>
<p className="learn-more">Learn more &raquo;</p>
</div>
</NavLink>

<NavLink to="/features">
<div>
<h4>Share via</h4>
<h1>Online platform</h1>
<h5>On your Mobile phone or link</h5>
<p>
<img src={OnlinePlatformIMG} alt="Image" />
</p>
<p className="learn-more">Learn more &raquo;</p>
</div>
</NavLink>

</div>
</div>
</div>
</div>
 
<div className="white-d-bg" id="testimonials">
<div className="container">
<div className="testimony-pro">
<h1>Testimonials</h1>
<h3>Join hundreds of people in embracing a digital identity.</h3>
<div className="slider">
<input type="radio" name="testimonial" id="t_1" checked={this?.state['testi_'] == '1' ? true : false} />
		<input type="radio" name="testimonial" id="t_2" checked={this?.state['testi_'] == '2' ? true : false} />
		<input type="radio" name="testimonial" id="t_3" checked={this?.state['testi_'] == '3' ? true : false} />
		<input type="radio" name="testimonial" id="t_4" checked={this?.state['testi_'] == '4' ? true : false}/>
		<input type="radio" name="testimonial" id="t_5" checked={this?.state['testi_'] == '5' ? true : false} />
		<div className="testimonials">
              {Testimonies?.map((testimony, i) => {
                if(i < 5){
                return (
                    <label className={"item " + ((i + 1) == this?.state['testi_'] ? 'active' : '')} htmlFor={"t_"+(i+1)} key={i}>
                <div className="itm">
				<img src={testimony?.image} alt={testimony?.name} />
				<p>{testimony?.testimony}</p>
				<h2>- {testimony?.name}</h2>
                </div>
			</label>
                )}
            })}
		</div>
		<div className="dots">
			<label htmlFor="t_1"></label>
			<label htmlFor="t_2"></label>
			<label htmlFor="t_3"></label>
			<label htmlFor="t_4"></label>
			<label htmlFor="t_5"></label>
		</div>
	</div>


</div>
</div>
</div>

<Footer />
</>
    )
}
}

export default Index;