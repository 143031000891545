import LogoIcon from '../Assets/logolight.png';
import { name, year, name_full, facebook, instagram, twitter, linkedin, youtube, tiktok, telegram, whatsapp, phone, email, about_us_pdf_url, compatibility_pdf_url,  terms_condition_pdf_url, privacy_policy_pdf_url, primary_color, demo_profile_url } from '../Constant';
import $ from "jquery";
import { ScrollToTop } from './core';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

window.addEventListener("scroll", function(){  
  if(this.scrollY > 300){
  $('.scroll-top').removeClass('hide');
    }
    else{
  $('.scroll-top').addClass('hide');
    }
  });
  
  $(window).on('scroll', function() {
    if ($(window).scrollTop() > 300) {
      $('.scroll-top').removeClass('hide');
    } else {
      $('.scroll-top').addClass('hide');
    }
  });


  $(document, window).on('click', 'input.faq-input', function(this: HTMLInputElement, e){
    if($(this).hasClass('checked')){
      $(this).removeClass('checked');
    }
    else{
    $(this).addClass('checked');
    }
    $('.faq-accordion input.faq-input[type="radio"]').not(this).removeClass('checked');
})

function Footer(props : any) : any {
    return (
      <>

<div className="container" id="faq">

<section className="faq-container">
      <p className="text-lg text-center margin-bottom">Frequently Asked Questions</p>

      <ul className="faq-accordion text-md">
        <li>
          <input className="faq-input" type="radio" name="faq" id="faq-1" />
          <label className="faq-label" htmlFor="faq-1">Is Qliq Card free? <i className="fa fa-chevron-down"></i> </label>
          <div className="faq-content">
            <p>
            Yes! We offer both free and paid plans.
            </p>
          </div>
        </li>

        <li>
          <input className="faq-input" type="radio" name="faq" id="faq-2" />
          <label className="faq-label" htmlFor="faq-2">What does the free plan include? <i className="fa fa-chevron-down"></i></label>
          <div className="faq-content">
            <p>With Qliq Card free plan, you can create and share digital business cards and profile pages. You can also modify your details at any time.
               If you want you can order Qliq NFC products, link it with your digital business/profile pages for best experience.</p>
          </div>
        </li>

        <li>
          <input className="faq-input" type="radio" name="faq" id="faq-3" />
          <label className="faq-label" htmlFor="faq-3">Can I order Qliq NFC product without a plan?<i className="fa fa-chevron-down"></i></label>
          <div className="faq-content">
            <p>
            Yes! We want to give the chance to people and small teams to use our Qliq NFC product without monthly fees. Therefore, you can order unlimited NFC business cards via our website and still use the benefits from Qliq Card platform for free with our free plan. Order your business cards from <NavLink to="/shop"> <b style={{color: primary_color}}>Here <i className="fa fa-arrow-right"></i></b></NavLink>.
            </p>
          </div>
        </li>

        <li>
          <input className="faq-input" type="radio" name="faq" id="faq-4" />
          <label className="faq-label" htmlFor="faq-4">How do I upgrade my free Qliq free plan to paid? <i className="fa fa-chevron-down"></i></label>
          <div className="faq-content">
            <p>Go to the <Link to="/login"><b style={{color: primary_color}}>Qliq Card dashboard</b></Link> on your device then proceed with the plan section to upgrade.</p>
          </div>
        </li>

        <li>
          <input className="faq-input" type="radio" name="faq" id="faq-5" />
          <label className="faq-label" htmlFor="faq-5">Do you offer any discounts? <i className="fa fa-chevron-down"></i></label>
          <div className="faq-content">
            <p>We offer personal quotes for larger teams and organizations depending on the amount of business cards ordered. To get a personal quote, send an email to <b style={{color: primary_color}}>{email}</b></p>
          </div>
        </li>
      </ul>
    </section>

</div>




     <a href="#top" className="scroll-top hide" onClick={() => ScrollToTop()}>
  <i className="fa-solid fa-arrow-up"></i>
</a>   
<footer className={props.classX? props.classX : ''}> 
<div className="container">
<div className="bold left" style={{fontSize: '23px'}}>
<img src={LogoIcon} alt={name}/>
</div>
        <div className="foot-list-nav">

        <div>
       
        <div className="left">
        Qliq card is an online social networking platform,  Create and share your digital business profile easily with our NFC product. 
        Design your profile to match your brand, and share it with customers around the globe.
        </div>
        <div className="socials">
        {facebook != "" ? (<Link to={facebook} hidden={facebook == ""} title="Facebook"><i className="fab fa-facebook"></i></Link>) : (<></>)}
        {instagram != "" ? (<Link to={instagram} hidden={instagram == ""} title="Instagram"><i className="fab fa-instagram"></i></Link>) : (<></>)}
        {linkedin != "" ? (<Link to={linkedin} hidden={linkedin == ""} title="LinkedIn"><i className="fab fa-linkedin"></i></Link>) : (<></>)}
        {twitter != "" ? (<Link to={twitter} hidden={twitter == ""} title="Twitter"><i className="fab fa-twitter"></i></Link>) : (<></>)}
        {youtube != "" ? (<Link to={youtube} hidden={youtube == ""} title="YouTube"><i className="fab fa-youtube"></i></Link>) : (<></>)}
        {tiktok != "" ? (<Link to={tiktok} hidden={tiktok == ""} title="TikTok"><i className="fab fa-tiktok"></i></Link>) : (<></>)}
        {whatsapp != "" ? (<Link to={whatsapp} hidden={whatsapp == ""} title="WhatsApp"><i className="fab fa-whatsapp"></i></Link>) : (<></>)}
        {telegram != "" ? (<Link to={telegram} title="Telegram"><i className="fab fa-telegram"></i></Link>) : (<></>)}
        </div>

        </div>
       
        <div>
        <NavLink to="/shop">
        Buy QLIQ business card
        </NavLink>

        <NavLink to="#how-it-works" href-target="#how-it-works">
        How it works
        </NavLink>

        <NavLink to="/features">
        Features
        </NavLink>

        <NavLink to="/pricing">
        Pricing
        </NavLink>
        </div>


         <div>

        <a href={demo_profile_url}>
        Demo business Profile
        </a>

        <NavLink to="/contact">
        Become a Reseller
        </NavLink>

        <NavLink to="#faq" href-target="#faq">
        FAQs
        </NavLink>

        <NavLink to="#testimonials" href-target="#testimonials">
        Testimonials
        </NavLink>
        </div>


         <div>

        <NavLink to="/contact">
        Contact Us
        </NavLink>

        <a href={about_us_pdf_url}>
        About Us
        </a>

        <a href={compatibility_pdf_url}>
        Compatibility
        </a>

        <a href={terms_condition_pdf_url}>
        Terms and Condition
        </a>

        <a href={privacy_policy_pdf_url}>
        Privacy Policy
        </a>
        </div>

        </div>


        <div className="copyright">
          <div>&copy; {year} By <NavLink to="/">{name}</NavLink>. &reg; All Rights Reserved by {name_full}. Trademark of <a href="https://greytechconsult.com/"> GREYTECH KONSULT</a>.</div>
        </div>

        </div>

        </footer>
        </>
)
}

export default Footer;