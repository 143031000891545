import $ from 'jquery';
import { currency } from '../Constant';

export const ScrollToTop = () : any => {
    $(function(){
    window.scrollTo(0, 0);
    $(document).find('body').scrollTop(0, 0);
    });
  }

  export const randomNum = (arr) => {
    return Math.floor(Math.random() * arr?.length);
  }

  export const toMoney = (amount, col = 2) => {
  return currency+""+formatMoney(amount, col, '.', ',');
  }

  function formatMoney(number, decPlaces, decSep, thouSep) {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    var i : any = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
        (j ? i?.substr(0, j) + thouSep : "") +
        i?.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}